<template>
  <div>
    <div class="header">
      <div class="back" @click="back">
        <div class="icon">
          <awesome-icon icon="chevron-left"/>
        </div>
      </div>
    </div>
    <div class="item">

      <div class="thumbnail">
        <div class="thumbnail-inner">
          <img alt="thumbnail" :src="getVideoThumbnail(item)"/>
        </div>
      </div>

      <div class="info">

        <div class="item-name">
          {{ getMetadataValue(item, 'MediaItem.name', item.name) }}
        </div>

        <div class="play">
          <div class="button" @click="playpause">
            <div class="play-button" v-if="playing === item.id">
              <awesome-icon icon="stop"/>
              <div class="text">
                Stop
              </div>
            </div>
            <div class="play-button" v-else>
              <awesome-icon icon="play"/>
              <div class="text">
                Play
              </div>
            </div>
          </div>
        </div>

        <div class="info-row" v-if="item.description">
          <div class="description" style="line-height: 140%;margin-top:2em;margin-bottom: 2em;">
            {{ item.description }}
          </div>
        </div>

        <div class="play" style="text-align: center;">
          <a class="button" :href="leadLink" target="_blank" title="Would you like to know more?">
            <div class="play-button">
              <!--                    <awesome-icon icon="play"/>-->
              <div class="text">
                Learn More
              </div>
            </div>
          </a>
        </div>

      </div>
    </div>

    <div class="motion swipe" v-if="motionSwipeActive" v-touch:swipe.prevent="swipeEvent">
      <div class="button enter" @click.stop="swipeEvent('enter')" :class="{active:direction === 'enter'}">
        OK
      </div>
      <div class="swipe-dirs">
        <div class="swipe-dir left" @click.stop="swipeEvent('left')" :class="{active:direction === 'left'}">
          <awesome-icon icon="chevron-left"/>
        </div>
        <div class="swipe-dir right" @click.stop="swipeEvent('right')" :class="{active:direction === 'right'}">
          <awesome-icon icon="chevron-right"/>
        </div>
        <div class="swipe-dir up" @click.stop="swipeEvent('top')" :class="{active:direction === 'top'}">
          <awesome-icon icon="chevron-up"/>
        </div>
        <div class="swipe-dir down" @click.stop="swipeEvent('bottom')" :class="{active:direction === 'bottom'}">
          <awesome-icon icon="chevron-down"/>
        </div>
      </div>
    </div>

    <div class="motion mouse" v-if="motionMouseActive">
      <motion-mouse @mouse="$emit('mouse', $event)"></motion-mouse>
    </div>
  </div>
</template>

<script>
import MotionMouse from "@/views/components/motion-mouse";

export default {
  name: "sc-item",
  components: {MotionMouse},
  props: {
    item: {},
    playing: {},
    playingItem: {}
  },

  data() {
    return {
      motionSwipeActive: false,
      motionMouseActive: false,
      direction: '',
      leadLink: process.env.VUE_APP_LEAD_LINK
    }
  },

  filters: {
    secondsToMinutes(val) {
      return Math.floor(val / 60) + ':' + ((val % 60 < 10) ? '0' : '') + val % 60;
    },
    millisecondsToMinutes(val) {
      val = val / 1000
      return Math.floor(val / 60) + ':' + ((val % 60 < 10) ? '0' : '') + Math.round(val % 60);
    }
  },

  watch: {
    playing(newVal) {
      if (newVal !== this.item.id) {
        this.stopMotion();
      } else if (newVal === this.item.id && (this.getMetadataValue(this.playingItem, 'MediaItem.motion') === 'swipe')) {
        this.activateMotion('swipe')
      } else if (newVal === this.item.id && (this.getMetadataValue(this.playingItem, 'MediaItem.motion') === 'mouse')) {
        this.activateMotion('mouse')
      }
    }
  },

  mounted() {
    if (this.playing === this.item.id && (this.getMetadataValue(this.playingItem, 'MediaItem.motion') === 'swipe')) {
      this.activateMotion('swipe')
    }
    if (this.playing === this.item.id && (this.getMetadataValue(this.playingItem, 'MediaItem.motion') === 'mouse')) {
      this.activateMotion('mouse')
    }
  },

  beforeDestroy() {
    this.stopMotion();
  },

  methods: {
    playpause() {
      if (!this.playing || (this.playing !== this.item.id)) {
        if (this.getMetadataValue(this.item, 'MediaItem.motion') === 'swipe') {
          this.motionSwipeActive = true;
        }
      }
      this.$emit('playpause', this.item)
    },

    activateMotion(type) {

      let property;
      if (type === 'swipe') {
        property = 'motionSwipeActive';
      }
      if (type === 'mouse') {
        property = 'motionMouseActive';
      }

      this[property] = true;

      let bodies = document.getElementsByTagName('body');
      if (bodies[0]) {
        bodies[0].setAttribute('style', 'overflow:hidden');
      }
    },

    stopMotion() {
      this.motionSwipeActive = false;
      this.motionMouseActive = false;

      let bodies = document.getElementsByTagName('body');
      if (bodies[0]) {
        bodies[0].removeAttribute('style');
      }
    },

    swipeEvent(event) {
      this.direction = event;
      this.$emit('swipe', event)

      setTimeout(() => {
        this.direction = '';
      }, 300)
    },

    moveMouse() {
      if (this.socket) {

      }
    },

    back() {
      this.$router.push({query: {category: this.$route.query.category}})
    },

    getVideoThumbnail(item) {
      return `${process.env.VUE_APP_URL_API}/thumbnails-media/${item.id}/thumb-${item.id}.png`;
    },

    getMetadataValue(media, name, placeholder = 'none') {
      if (media.metadata === null) {
        return placeholder
      }

      let meta = JSON.parse(media.metadata);

      let metadata = meta.find(x => x['metadata']['name'] === name)
      if (!metadata) {
        return placeholder
      }

      // String Metadata
      if (metadata.metadata.valueType === 'ANY' && metadata.metadata.datatype === 'STRING') {
        return metadata.value
      }

      // Integer Metadata
      if (metadata.metadata.valueType === 'ANY' && metadata.metadata.datatype === 'INTEGER') {
        return metadata.value
      }

      // Picklist Metadata
      if (metadata.metadata.valueType === 'PICKLIST' && metadata.metadata.datatype === 'STRING') {
        if (!metadata.metadata.predefinedValues) {
          return placeholder;
        }
        let val = metadata.metadata.predefinedValues.find(x => x.id === parseInt(metadata.value))
        if (!val) {
          return placeholder;
        } else {
          return val.value
        }
      }

    },
  }
}
</script>

<style scoped lang="scss">
.header {
  position: relative;
  z-index: 3;

  .back {
    position: absolute;
    left: 0;
    top: 0;
    padding: 2em 1.5em 0.5em;

    .icon {
      font-size: 1.2em;
      color: #fff;
    }
  }
}

.item {
  position: relative;
  padding-top: 1.5em;

  .thumbnail {
    height: 10em;
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;

    .thumbnail-inner {
      position: absolute;
      right: 1.5em;
      width: 17em;
      height: 100%;
      transform-origin: center;
      border: 5px solid #000;
      box-sizing: border-box;
      border-bottom: 10px solid #000;
      -webkit-backface-visibility: hidden;
      animation: turn 1s $easing 0.2s both;

      @keyframes turn {
        from {
          opacity: 0;
          right: 10em
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .play {
    margin: 1em 0 1em;
    font-size: 1.3em;
    z-index: 1;

    .button, a {
      border-radius: 100px;

      .play-button {
        display: flex;
        align-items: center;
        font-size: 0.8em;

        .text {
          margin-left: 0.5em;

        }
      }
    }

  }

  .info {
    color: #fff;
    padding: 1.5em 2em;
    animation: info-in 1.1s $easing 0.5s both;

    @keyframes info-in {
      from {
        transform: translateX(-60px);
        opacity: 0;
      }
    }

    .item-name {
      font-size: 2em;
      letter-spacing: 0.02em;
      font-weight: 300;
      line-height: 1em;
      margin-bottom: 0.5em;
    }

    .info-row {
      margin-bottom: 1.2em;

      .label {
        font-size: 0.7em;
        font-weight: 700;
        color: #ddd;
      }

      .value {
        word-break: break-all;
      }
    }
  }
}

.motion {
  background: $color-secondary;
  color: #fff;
  font-weight: 700;
  animation: slideup 0.6s $easing;

  @keyframes slideup {
    from {
      transform: translateY(100%)
    }
  }

  z-index: 4;
  position: fixed;
  top: 4em;
  bottom: 9.5em;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.swipe {
    .enter {
      background: #ddd;
      color: #111;
      font-size: 2em;
      padding: 1.5em 1.2em;
      z-index: 5;
      text-transform: uppercase;

      &:active {
        transform: scale(0.9)
      }
    }

    .swipe-dirs {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      color: rgba(255, 255, 255, 0.4);

      .swipe-dir {
        font-size: 3em;
        position: absolute;
        padding: 1em;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, .3);

        &.up {
          width: 100%;
          top: 0;
        }

        &.down {
          width: 100%;
          bottom: 0;
        }

        &.left {
          left: 0;
          height: 100%;
        }

        &.right {
          right: 0;
          height: 100%;
        }

        &.active {
          animation: glow .3s ease-in-out;

          @keyframes glow {
            from {
              background: rgba(0, 0, 0, 0.8);
            }
          }
        }
      }
    }
  }
}

</style>
